// eslint-disable-next-line import/no-cycle
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR,
} from '../actions-iotech';

export const loginUser = (loginUserValues, history) => ({
  type: LOGIN_USER,
  payload: { loginUserValues, history },
});
export const loginUserSuccess = (loginUserValues) => ({
  type: LOGIN_USER_SUCCESS,
  payload: loginUserValues,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const refreshToken = (refreshTokenValue, history) => ({
  type: REFRESH_TOKEN,
  payload: { refreshTokenValue, history },
});
export const refreshTokenSuccess = (refreshTokenValues) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: refreshTokenValues,
});
export const refreshTokenError = (message) => ({
  type: REFRESH_TOKEN_ERROR,
  payload: { message },
});

export const logoutUser = (accessTokenValue, history, callAPI = true) => ({
  type: LOGOUT_USER,
  payload: { accessTokenValue, history, callAPI },
});
export const logoutUserSuccess = (logoutUserValues) => ({
  type: LOGOUT_USER_SUCCESS,
  payload: logoutUserValues,
});
export const logoutUserError = (message) => ({
  type: LOGOUT_USER_ERROR,
  payload: { message },
});
