import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LOGIN_USER, REFRESH_TOKEN, LOGOUT_USER } from '../actions-iotech';
import {
  loginUserSuccess,
  loginUserError,
  refreshTokenSuccess,
  refreshTokenError,
  logoutUserSuccess,
  logoutUserError,
} from './actions-iotech';
import { adminRoot, apiPath } from '../../constants/defaultValues-iotech';
import {
  setCurrentUser,
  getCurrentColor,
  setCurrentColor,
  getCurrentRadius,
  setCurrentRadius,
  getCurrentLanguage,
  setCurrentLanguage,
  setClearLocalStorage,
  getLoginStoreIndex,
  setLoginStoreIndex,
} from '../../helpers/Utils-iotech';

// LOGIN_USER
export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithStoreIndexUsernamePassword);
}
const loginWithStoreIndexUsernamePasswordAsync = async (
  store,
  user,
  pass,
  ip
) => {
  const bodyData = JSON.stringify({
    store_index: store,
    username: user,
    password: pass,
    ip_address: ip,
  });
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: bodyData,
  };
  const response = await fetch(`${apiPath}/user/login`, requestOptions);
  const data = await response.json();

  return data;
};
function* loginWithStoreIndexUsernamePassword({ payload }) {
  const { loginUserValues, history } = payload;
  try {
    const loginUser = yield call(
      loginWithStoreIndexUsernamePasswordAsync,
      loginUserValues.store_index,
      loginUserValues.username,
      loginUserValues.password,
      loginUserValues.ip_address
    );

    if (!loginUser.message) {
      setCurrentUser(loginUser);
      setLoginStoreIndex(loginUserValues.store_index);
      yield put(loginUserSuccess(loginUser));
      history.push(adminRoot);
    } else {
      yield put(loginUserError(loginUser));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

// REFRESH_TOKEN
export function* watchRefreshToken() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REFRESH_TOKEN, refreshToken);
}
const refreshTokenAsync = async (token) => {
  const bodyData = JSON.stringify({
    refresh_token: token,
  });
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: bodyData,
  };
  const response = await fetch(
    `${apiPath}/user/refresh-access-token`,
    requestOptions
  );
  const data = await response.json();

  return data;
};
function* refreshToken({ payload }) {
  const { refreshTokenValue, history } = payload;
  try {
    const refreshTokenUser = yield call(refreshTokenAsync, refreshTokenValue);
    if (refreshTokenUser?.message?.includes('Success')) {
      yield put(refreshTokenSuccess(refreshTokenUser));
    } else {
      yield put(refreshTokenError(refreshTokenUser));
      // 清空LocalStorage，並儘保留theme_selected_color、theme_radius、current_language
      const themeRadius = getCurrentRadius();
      const currentLanguage = getCurrentLanguage();
      const themeSelectedColor = getCurrentColor();
      const loginStoreIndex = getLoginStoreIndex();
      setClearLocalStorage();
      setCurrentRadius(themeRadius);
      setCurrentLanguage(currentLanguage);
      setCurrentColor(themeSelectedColor);
      setLoginStoreIndex(loginStoreIndex);
      history.push('/user/login');
    }
  } catch (error) {
    yield put(refreshTokenError(error));
    // 清空LocalStorage，並儘保留theme_selected_color、theme_radius、current_language
    const themeRadius = getCurrentRadius();
    const currentLanguage = getCurrentLanguage();
    const themeSelectedColor = getCurrentColor();
    const loginStoreIndex = getLoginStoreIndex();
    setClearLocalStorage();
    setCurrentRadius(themeRadius);
    setCurrentLanguage(currentLanguage);
    setCurrentColor(themeSelectedColor);
    setLoginStoreIndex(loginStoreIndex);
    history.push('/user/login');
  }
}

// LOGOUT_USER
export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}
const logoutAsync = async (token) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'access-token': token,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${apiPath}/user/logout`, requestOptions);
  const data = await response.json();

  return data;
};
function* logout({ payload }) {
  const { accessTokenValue, history, callAPI } = payload;
  try {
    if (callAPI) {
      const logoutUser = yield call(logoutAsync, accessTokenValue);
      if (logoutUser?.message?.includes('Success')) {
        yield put(logoutUserSuccess('success'));
      } else {
        yield put(logoutUserError(logoutUser.message));
      }
    }
    // 清空LocalStorage，並儘保留theme_selected_color、theme_radius、current_language
    const themeRadius = getCurrentRadius();
    const currentLanguage = getCurrentLanguage();
    const themeSelectedColor = getCurrentColor();
    const loginStoreIndex = getLoginStoreIndex();
    setClearLocalStorage();
    setCurrentRadius(themeRadius);
    setCurrentLanguage(currentLanguage);
    setCurrentColor(themeSelectedColor);
    setLoginStoreIndex(loginStoreIndex);
    history.push('/user/login');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error) {
    yield put(logoutUserError(error));
    // 清空LocalStorage，並儘保留theme_selected_color、theme_radius、current_language
    const themeRadius = getCurrentRadius();
    const currentLanguage = getCurrentLanguage();
    const themeSelectedColor = getCurrentColor();
    const loginStoreIndex = getLoginStoreIndex();
    setClearLocalStorage();
    setCurrentRadius(themeRadius);
    setCurrentLanguage(currentLanguage);
    setCurrentColor(themeSelectedColor);
    setLoginStoreIndex(loginStoreIndex);
    history.push('/user/login');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchRefreshToken),
    fork(watchLogoutUser),
  ]);
}
