import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { MENU_GET_NOTIFICATION } from '../actions-iotech';
import { getNotificationSuccess, getNotificationError } from './actions-iotech';
import { apiPath } from '../../constants/defaultValues-iotech';

// MENU_GET_NOTIFICATION
export function* watchGetNotification() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(MENU_GET_NOTIFICATION, getNotification);
}
const getReservationApplyAsync = async (accessToken) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'access-token': accessToken,
    },
  };
  const response = await fetch(`${apiPath}/reservation/apply`, requestOptions);
  const data = await response.json();

  return data;
};
function* getNotification({ payload }) {
  const { accessToken, pageAuthority } = payload;
  try {
    if (pageAuthority.reservation) {
      const reservationApply = yield call(
        getReservationApplyAsync,
        accessToken
      );

      if (!reservationApply.message) {
        yield put(
          getNotificationSuccess({ reservation: reservationApply.total_number })
        );
      } else {
        yield put(getNotificationError(reservationApply));
      }
    }
  } catch (error) {
    yield put(getNotificationError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchGetNotification)]);
}
