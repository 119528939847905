import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR,
} from '../actions-iotech';
import { getCurrentUser } from '../../helpers/Utils-iotech';

const INIT_STATE = {
  currentUser: getCurrentUser(),
  forgotUserValues: '',
  resetUserValues: '',
  refreshTokenValues: '',
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: '',
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case REFRESH_TOKEN:
      return { ...state, loading: true, error: '' };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        refreshTokenValues: action.payload,
        error: '',
      };
    case REFRESH_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        refreshTokenValues: null,
        error: action.payload.message,
      };
    case LOGOUT_USER:
      return { ...state, currentUser: null, error: '' };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        logoutUserValues: action.payload,
        error: '',
      };
    case LOGOUT_USER_ERROR:
      return {
        ...state,
        logoutUserValues: '',
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
