import { combineReducers } from 'redux';
import menu from './menu/reducer-iotech';
import settings from './settings/reducer-iotech';
import authUser from './auth/reducer-iotech';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
});

export default reducers;
