/* eslint-disable global-require */
import './assets/css/vendor/bootstrap-min-iotech.css';
import './assets/css/vendor/bootstrap-rtl-only-min-iotech.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import {
  isMultiColorActive,
  defaultColor,
  isDarkSwitchActive,
} from './constants/defaultValues-iotech';
import { getCurrentColor, setCurrentColor } from './helpers/Utils-iotech';

const color =
  isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor;
setCurrentColor(color);

const render = () => {
  import(`./assets/css/sass/themes/${color}-iotech.scss`).then(() => {
    require('./AppRenderer-iotech');
  });
};
render();
