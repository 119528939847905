import {
  MENU_SET_CLASSNAMES,
  MENU_CONTAINER_ADD_CLASSNAME,
  MENU_CLICK_MOBILE_MENU,
  MENU_CHANGE_DEFAULT_CLASSES,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
  MENU_GET_NOTIFICATION,
  MENU_GET_NOTIFICATION_SUCCESS,
  MENU_GET_NOTIFICATION_ERROR,
  MENU_SET_NOTIFICATION,
} from '../actions-iotech';
import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} from '../../constants/defaultValues-iotech';

const INIT_STATE = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: defaultMenuType === 'menu-default', // if you use menu-sub-hidden as default menu type, set value of this variable to false
  pageNotification: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MENU_SET_CLASSNAMES:
      return {
        ...state,
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      };
    case MENU_CONTAINER_ADD_CLASSNAME:
      return { ...state, containerClassnames: action.payload };
    case MENU_CLICK_MOBILE_MENU:
      return {
        ...state,
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      };
    case MENU_CHANGE_DEFAULT_CLASSES:
      return { ...state, containerClassnames: action.payload };
    case MENU_CHANGE_HAS_SUB_ITEM_STATUS:
      return { ...state, selectedMenuHasSubItems: action.payload };
    case MENU_GET_NOTIFICATION:
      return { ...state, error: '' };
    case MENU_GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        pageNotification: action.payload,
        error: '',
      };
    case MENU_GET_NOTIFICATION_ERROR:
      return {
        ...state,
        pageNotification: null,
        error: action.payload.message,
      };
    case MENU_SET_NOTIFICATION:
      return { ...state, pageNotification: action.payload };
    default:
      return { ...state };
  }
};
