import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
} from '../constants/defaultValues-iotech';

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js : getDirection -> error',
      error
    );
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js : setDirection -> error',
      error
    );
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem('theme_selected_color')) {
      currentColor = localStorage.getItem('theme_selected_color');
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js : getCurrentColor -> error',
      error
    );
    currentColor = defaultColor;
  }
  return currentColor;
};
export const setCurrentColor = (color) => {
  try {
    localStorage.setItem('theme_selected_color', color);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js : setCurrentColor -> error',
      error
    );
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem('theme_radius')) {
      currentRadius = localStorage.getItem('theme_radius');
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem('theme_radius', radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('current_language') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('current_language')
      ).length > 0
        ? localStorage.getItem('current_language')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('current_language', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getPageAuthority = () => {
  let authority = null;
  try {
    authority =
      localStorage.getItem('page_authority') != null
        ? JSON.parse(localStorage.getItem('page_authority'))
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js  : getPageAuthority -> error',
      error
    );
    authority = null;
  }
  return authority;
};
export const setPageAuthority = (authority) => {
  try {
    if (authority) {
      localStorage.setItem('page_authority', JSON.stringify(authority));
    } else {
      localStorage.removeItem('page_authority');
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js : setPageAuthority -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('iotech_salesmap_current_user') != null
        ? JSON.parse(localStorage.getItem('iotech_salesmap_current_user'))
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js  : getCurrentUser -> error',
      error
    );
    user = null;
  }
  return user;
};
export const setCurrentUser = (user) => {
  function pageAuthorityDealer(authority) {
    const layerOne = {};
    const layerTwo = {};
    const layerThree = {};
    const newAuthority = {};
    // unfold object
    Object.keys(authority).forEach((layerOneKey) => {
      if (typeof authority[layerOneKey] === 'boolean') {
        layerOne[layerOneKey] = authority[layerOneKey];
      } else if (typeof authority[layerOneKey] === 'object') {
        layerOne[layerOneKey] = Object.keys(authority[layerOneKey]);
        Object.keys(authority[layerOneKey]).forEach((layerTwoKey) => {
          if (typeof authority[layerOneKey][layerTwoKey] === 'boolean') {
            layerTwo[layerTwoKey] = authority[layerOneKey][layerTwoKey];
          } else if (typeof authority[layerOneKey][layerTwoKey] === 'object') {
            layerTwo[layerTwoKey] = Object.keys(
              authority[layerOneKey][layerTwoKey]
            );
            Object.keys(authority[layerOneKey][layerTwoKey]).forEach(
              (layerThreeKey) => {
                if (
                  typeof authority[layerOneKey][layerTwoKey][layerThreeKey] ===
                  'boolean'
                ) {
                  layerThree[layerThreeKey] =
                    authority[layerOneKey][layerTwoKey][layerThreeKey];
                }
              }
            );
          }
        });
      }
    });
    // check layerTwo status
    Object.keys(layerTwo).forEach((layerTwoKey) => {
      if (typeof layerTwo[layerTwoKey] === 'object') {
        layerTwo[layerTwoKey].some((layerThreeKey) => {
          if (!layerThree[layerThreeKey]) {
            layerTwo[layerTwoKey] = false;
          } else {
            layerTwo[layerTwoKey] = true;
            return true;
          }
          return false;
        });
      }
    });
    // check layerThree status
    Object.keys(layerOne).forEach((layerOneKey) => {
      if (typeof layerOne[layerOneKey] === 'object') {
        layerOne[layerOneKey].some((layerTwoKey) => {
          if (!layerTwo[layerTwoKey]) {
            layerOne[layerOneKey] = false;
          } else {
            layerOne[layerOneKey] = true;
            return true;
          }
          return false;
        });
      }
    });
    const newAuthorityTemp = Object.assign(layerOne, layerTwo, layerThree);
    // replace '_' to '-'
    Object.keys(newAuthorityTemp).forEach((key) => {
      if (key.includes('_')) {
        newAuthority[key.replaceAll('_', '-')] = newAuthorityTemp[key];
      } else {
        newAuthority[key] = newAuthorityTemp[key];
      }
    });

    return newAuthority;
  }

  try {
    if (user) {
      localStorage.setItem(
        'iotech_salesmap_current_user',
        JSON.stringify(user)
      );
      setPageAuthority(pageAuthorityDealer(user.authority));
    } else {
      localStorage.removeItem('iotech_salesmap_current_user');
      setPageAuthority();
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js : setCurrentUser -> error',
      error
    );
  }
};

export const getLoginStoreIndex = () => {
  let currentStoreIndex = '';
  try {
    if (localStorage.getItem('store_index')) {
      currentStoreIndex = localStorage.getItem('store_index');
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js : getLoginStoreIndex -> error',
      error
    );
    currentStoreIndex = '';
  }
  return currentStoreIndex;
};
export const setLoginStoreIndex = (storeIndex) => {
  try {
    localStorage.setItem('store_index', storeIndex);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js : setLoginStoreIndex -> error',
      error
    );
  }
};

export const getAccessToken = () => {
  let token = null;
  try {
    token =
      localStorage.getItem('iotech_salesmap_current_user') != null
        ? JSON.parse(localStorage.getItem('iotech_salesmap_current_user'))
            .access_token
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js  : getAccessToken -> error',
      error
    );
    token = null;
  }
  return token;
};

export const getRefreshToken = () => {
  let token = null;
  try {
    token =
      localStorage.getItem('iotech_salesmap_current_user') != null
        ? JSON.parse(localStorage.getItem('iotech_salesmap_current_user'))
            .refresh_token
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js  : getRefreshToken -> error',
      error
    );
    token = null;
  }
  return token;
};

export const getStoreIndex = () => {
  let index = null;
  try {
    index =
      localStorage.getItem('iotech_salesmap_current_user') != null
        ? JSON.parse(localStorage.getItem('iotech_salesmap_current_user'))
            .store_index
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js  : getStoreIndex -> error',
      error
    );
    index = null;
  }
  return index;
};

export const getAvatarURL = () => {
  let url = null;
  try {
    url =
      localStorage.getItem('iotech_salesmap_current_user') != null
        ? JSON.parse(localStorage.getItem('iotech_salesmap_current_user')).image
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js  : getAvatarURL -> error',
      error
    );
    url = null;
  }
  return url;
};

export const setClearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils-iotech.js  : setClearLocalStorage -> error',
      error
    );
  }
  return null;
};
